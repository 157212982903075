import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const Container = styled.div`
  flex-direction: column;
  color: white;
  width: 500px;
  margin: 25px 20px 20px 20px;
  display: flex;
  font-weight: 500;
  color: #D1D1D1;
`;

const TopContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Video = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
`;

const FacesVideo = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
  margin-top: 15px;
`;

const TopText = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
`;

const Logo = styled.div`
  font-weight: bold;
  color: #fea700;
  text-shadow: 0 0 15px rgba(0, 0, 0, 1);
  text-align: center;
`;

const TopLogo = styled.div`
  font-size: 45px;

  @media (max-width: 560px) {
    font-size: 30px;
  }
`;

const MiniLogo = styled.div`
  font-size: 35px;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const ConceptText = styled.div`
  color: black;
  text-shadow: 0 0 15px white;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;

  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

const CreateButton = styled.span`
  margin-top: 10px;
  border-radius: 5px;
  height: 44px;

  background-color: rgb(254, 167, 0);
  color: black;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: black;

  &:hover {
    background-color: #f8be53;
  }
`;

const PornSiteIconList = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const PornSiteIconListCenter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`;

const PornSiteIcon = styled.img`
  height: ${x=> x.height};
  margin: 0px 5px;
`;

const StepImg = styled.img`
  width: calc(100% - 20px);
  margin: 10px;
`;

const StepImgContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1d1d1d;
  border-radius: 10px;
  border: 1px solid gray;
`;

const StepB = styled.span`
  font-weight: bold;
  color: #fea700;
`;

const StepSubtitle = styled.span`
  font-weight: bold;
  font-size: 15px;
  color: #C0C0C0;
`;

const StepTitle = styled.legend`
  font-weight: 700;
  font-size: 18px;
`;

const StepContainer = styled.fieldset`
  border-radius: 10px;
  background-color: #2b2b2b;
  border: 1px solid #424242;
  padding: 20px;
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: #fea700;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const SectionText = styled.div`
  font-size: 18px;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 560px) {
    font-size: 16px;
  }
`;

const TweetImgContainer = styled.div` 
  margin-top: 20px;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  padding-top: 15px;
  background-color: white;
  position: relative;
`;

const TweetCatch = styled.span`
  position: absolute;
  font-size: 20px;
  z-index: 100;
  color: black;
  right: 20px;
  font-weight: bold;
`;

const TweetImg = styled.img`
  width: 100%;
`;

const TweetVideo = styled.video<{ width: string, top: string, left: string }>`
  position: absolute;
  z-index: 50;
  width: 100%;
  border-radius: 10px;
  width: ${props => props.width};
  top: ${props => props.top};
  left: ${props => props.left};
`;

const ExTitle = styled.div`
  color: #FFCC00;
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 15px;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const ExText = styled.div`
  font-size: 16px;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 560px) {
    font-size: 14px;
  }
`;

const ExVideo = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
  margin-bottom: 15px;
  margin-top: 10px;
`;

const PriceImg = styled.img`
  width: 100%;
  border-radius: 15px;
  border: 1px solid #424242;
  margin-top: 10px;
`;

const LinkButtonContainer = styled.div`
  margin-top: 20px;
`;

const LogoContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #fea700;

  @media (max-width: 560px) {
    padding: 10px;
  }
`;

const PriceText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 10px;
`;

const Emphasis = styled.span`
  font-weight: bold;
  color: white;
`;

const AwardContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 10px;
  display: flex;
`;

const AwardImg = styled.img`
  max-width: 150px;
`;

const AwardText = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
`;

const LinkButton: FC<{}> = (props) => {
  return <LinkButtonContainer>
    <Link to="/create">
      <CreateButton>
        <Trans>Let's face swap porn in 10 seconds!</Trans>
      </CreateButton>
    </Link>
  </LinkButtonContainer>;
};

       // <PornSiteIcon src="/imgs/icons/xhamster.svg" height={36}/>
       // <PornSiteIcon src="/imgs/icons/xhamster.svg" height={30}/>
       
       // /lp/ex/faces.mp4
       // /lp/card/top_faces.mp4
        //<PornSiteIcon src="/imgs/icons/pornhub.svg" height={40}/>

const HomePage: FC<{}> = (props) => {
  return <><Center>
    <Container>
      <TopContainer>
        <Video src="/lp/ex/act1.mp4" autoPlay loop muted playsInline/>
        <TopText>
          <LogoContainer>
            <Logo>
              <TopLogo>
                <Trans>Face Swap Porn</Trans>
              </TopLogo>
              <MiniLogo>
              <Trans>in 10 seconds</Trans>
              </MiniLogo>
            </Logo>
            <ConceptText>
            <Trans>Make your best porn to swap faces!</Trans>
            </ConceptText>
          </LogoContainer>
        </TopText>
      </TopContainer>

      <SectionTitle>
      <Trans>Revolutionize your favorite porn <br /> with face swap ⚡️</Trans>
      </SectionTitle>
      <SectionText>
      <Trans>Just by entering the video URL (compatible with <Emphasis>XVideos</Emphasis>) and a face photo, 
        it can swap the face and stream the video in just 10 seconds.
        You can create porn with your favorite face and body. </Trans>
      </SectionText>
      <PornSiteIconListCenter>
        <PornSiteIcon src="/imgs/icons/xvideos.svg" height={28}/>
      </PornSiteIconListCenter>

      <FacesVideo src="/lp/ex/faces.mp4" autoPlay loop muted playsInline/>

      <SectionTitle>
      <Trans>Application of state-of-the-art <br/> AI technology</Trans>
      </SectionTitle>
      <SectionText>
      <Trans>We use the most accurate AI model in the world, developed by researchers at Google and Meta, which has won several international AI competitions.
        Experience the world's best technology!</Trans>
      </SectionText>

      <AwardContainer>
        <AwardText>
          2023.06: <Trans>Rank 1st of ECTV 2023 WCAP Challenge.</Trans> <br />
          2022.11: <Trans>Rank 1st of NEST-FRXT 1 on 1. </Trans><br />
          2020.09: <Trans>Rank 1st of WILDER Face Detection Challenge 2020.</Trans>
        </AwardText>
        <AwardImg src="/lp/awards.png" />
      </AwardContainer>

      <LinkButton />

      <SectionTitle>
      <Trans>Make your dreams come true ✨</Trans>
      </SectionTitle>

      <ExTitle>
      <Trans>Attractive girls around you</Trans>
      </ExTitle>
      <ExText>
      <Trans>Your workplace or school crush, the cute person you met yesterday,
        the attractive girls you interact with regularly. 
        Try uploading just one photo from Instagram.
        You can instantly watch their sex videos.</Trans>
      </ExText>
      <ExVideo src="/lp/ex/gf1.mp4" autoPlay loop muted playsInline/>

      <ExTitle>
      <Trans>Ex-girlfriend</Trans>
      </ExTitle>
      <ExText>
      <Trans>Have sex with your ex-girlfriend again.
        You once had a cute girlfriend, but then you unexpectedly broke up.
        Get back the unforgettable moments with her.
        This service lets you do that with just one photo.</Trans>
      </ExText>
      <ExVideo src="/lp/ex/gf2.mp4" autoPlay loop muted playsInline/>

      <ExTitle>
      <Trans>Famous actress</Trans>
      </ExTitle>
      <ExText>
      <Trans>Who's your favorite actress?
        If you wish, you can create porn featuring her in just 10 seconds.
        This includes Hollywood stars, idols, top models, singers, talents, dancers, and more. 
        You can watch women you've seen on TV or in movies having sex on your device.</Trans>
      </ExText>
      <ExVideo src="/lp/ex/act2.mp4" autoPlay loop muted playsInline/>

      <LinkButton />

      <SectionTitle>
      <Trans>Hugely viral on social media 🔥</Trans>
      </SectionTitle>

      <SectionText>
      <Trans>On social media, it spread with overwhelming momentum, garnering billions of impressions. 
        It has completely transformed the global porn lifestyle.</Trans>
      </SectionText>

      <TweetImgContainer>
        <TweetCatch><Trans>105K Reposts🔥</Trans></TweetCatch>
        <TweetVideo src="/lp/posts/4.mp4" autoPlay loop muted playsInline width="93%" top="21%" left="3.5%"/>
        <TweetImg src="/lp/posts/4.jpg" />
      </TweetImgContainer>

      <TweetImgContainer>
        <TweetCatch><Trans>187K Reposts🔥</Trans></TweetCatch>
        <TweetVideo src="/lp/posts/3.mp4" autoPlay loop muted playsInline width="94%" top="17%" left="3%"/>
        <TweetImg src="/lp/posts/3.jpg" />
      </TweetImgContainer>

      <TweetImgContainer>
        <TweetCatch><Trans>113K Reposts🔥</Trans></TweetCatch>
        <TweetVideo src="/lp/posts/1.mp4" autoPlay loop muted playsInline width="92%" top="16%" left="4%" />
        <TweetImg src="/lp/posts/1.jpg" />
      </TweetImgContainer>

      <TweetImgContainer>
        <TweetCatch><Trans>121K Reposts🔥</Trans></TweetCatch>
        <TweetVideo src="/lp/posts/2.mp4" autoPlay loop muted playsInline width="93%" top="23.5%" left="3.5%"/>
        <TweetImg src="/lp/posts/2.jpg" />
      </TweetImgContainer>
      <LinkButton />

      <SectionTitle>
      <Trans>Thank you to 1 million users 🚀</Trans>
      </SectionTitle>

      <SectionText>
      <Trans>So far, 1 million users have swapped faces. 
        Moving forward, we'll continue to deliver the ultimate excitement through the porn revolution.</Trans>
      </SectionText>

      <SectionTitle>
      <Trans>Only three steps 😃</Trans>
      </SectionTitle>

      <StepContainer>
        <StepTitle>STEP1: <StepSubtitle><Trans>SET VIDEO URL</Trans></StepSubtitle></StepTitle>
        <Trans>Enter <StepB>the video URL</StepB> of the porn site.</Trans>
        <PornSiteIconList>
          <PornSiteIcon src="/imgs/icons/xvideos.svg" height={22}/>
          <PornSiteIcon src="/imgs/icons/pornhub.svg" height={32}/>
        </PornSiteIconList>
        <StepImgContainer>
          <StepImg src="/lp/steps/1.jpg"/>
        </StepImgContainer>
      </StepContainer>

      <StepContainer>
        <StepTitle>STEP2: <StepSubtitle><Trans>UPLOAD FACE IMAGE</Trans></StepSubtitle></StepTitle>
        <Trans>Upload <StepB>your favorite face</StepB> you want to replace the original face.</Trans>
        <StepImgContainer>
          <StepImg src="/lp/steps/2.jpg"/>
        </StepImgContainer>
      </StepContainer>

      <StepContainer>
        <StepTitle>STEP3: <StepSubtitle><Trans>LET'S GENERATE</Trans></StepSubtitle></StepTitle>
        <Trans>Just wait <StepB>10 seconds</StepB> to stream the generated video!</Trans>
        <StepImgContainer>
          <StepImg src="/lp/steps/3.png"/>
        </StepImgContainer>
      </StepContainer>

      <SectionTitle>
      <Trans>Price 💰</Trans>
      </SectionTitle>
      <SectionText>
        <PriceText>
        <Trans>$9.99 / a video</Trans>
        </PriceText>
        <Trans>Simple pricing at just $9.99 per video generated. There are no subscriptions.</Trans>
      </SectionText>
      <LinkButton />
      <br />
      <br />
    </Container>
  </Center>
  </>
};

export default HomePage;